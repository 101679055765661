
.main-content{
  padding: 0;
  width: 100%;
  overflow-x: hidden;
}


.secondsection-item-emoji .MuiSvgIcon-fontSizeMedium{
  width: 2.5em;
  height: auto;
}

.svg-inline--fa{
  font-size: 1.5em;
}

.header-account-icon{
  margin: auto;
}

.header-account-icon .css-i4bv87-MuiSvgIcon-root{
  margin-bottom: auto;
  cursor: pointer;
  color: rgb(82, 82, 82);
  font-size: 2.5em;
}