.dashboardWaitlistCustomer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 0px;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    height: 80px;
    transition: border 0.3s;
}
.dashboardWaitlistCustomer:hover{
    /* scale: 1.005; */
    border: 1px solid rgb(104, 0, 145) ;
}

.dashboardWaitlistCustomer-info{
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.dashboardWaitlistCustomer-info-item{
    width: 20%;
    margin: 0 auto;
    font-size: 18px;
}

.dashboardWaitlistCustomer-phone-info{
    display: none;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.dashboardWaitlistCustomer-phone-info-row{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.dashboardWaitlistCustomer-cta{
    /* width: 20%; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;
}

.dashboardWaitlistCustomer-cta-service{
    color: #008332; 
    cursor: pointer;
}
.dashboardWaitlistCustomer-cta-service:hover{
    color: #00a02d;
}

.dashboardWaitlistCustomer-cta-remove{
    color: rgb(179, 0, 0); 
    cursor: pointer;
    font-size: 1.2rem;
}
.dashboardWaitlistCustomer-cta-remove:hover{
    color: #e40000;
}

.dashboardWaitlistCustomer-cta-invite{
    width: 100px;
    height: 40px;
    font-weight: bold;
    background-color: #5a189a; 
    color: white;
    padding: 8px 12px;
    border: 1px solid black;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 12px;
    margin: 0 10px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.dashboardWaitlistCustomer-cta-invite:hover {
    background-color: #7325c1;
}

@media (max-width: 768px) {
    .dashboardWaitlistCustomer{
        flex-direction: column;
        justify-content: space-between;
        height: auto;
        width: 90%;
    }

    .dashboardWaitlistCustomer-info{
        display: none;
    }

    .dashboardWaitlistCustomer-phone-info{
        display: flex;
        margin-bottom: 20px;
    }

    .dashboardWaitlistCustomer-info-item{
        width: 100%;
        font-size: 20px;
        padding: 20px 0;
    }

    .dashboardWaitlistCustomer-cta{
        justify-content: space-around;
        margin-right: 0px;
        margin-top: 0px;
    }
    
    .dashboardWaitlistCustomer-cta-invite{
        width: 50%;
    }
}