
.customer-row-publicwaitlist {
    background-color: #f5f5f5;
    border-bottom: 10px solid #ffffff;
}
.customer-row-publicwaitlist.target {
    background-color: #189a5085  !important;
    border-bottom: 10px solid #ffffff  !important;
    font-weight: bold;
}

.customer-row-publicwaitlist.target::before {
    background-color: #189a5085;
    border-bottom: 10px solid #ffffff;
}


