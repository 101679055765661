.not-found-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    padding: 20px;
  }
  
  .not-found-container h1 {
    font-size: 5em;
    margin: 0;
  }
  
  .not-found-container h2 {
    font-size: 2em;
    margin: 10px 0;
  }
  
  .not-found-container p {
    margin: 20px 0;
  }
  
  .not-found-container a {
    display: inline-block;
    background-color: rgba(89, 27, 176, 0.93);
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s;
  }
  
  .not-found-container a:hover {
    background-color: rgba(59, 15, 121, 0.93);
  }
  