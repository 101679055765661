.login{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    background-color: rgb(255, 255, 255);
    /* background-position: 50% 50%; */
    height: 600px;
    z-index: 1;
}

.login__logo{
    padding: 20px 5%;
    margin-right: auto;
}

.login__logo img{
    height: 50px; 
}

.login__container{
    margin: 0 auto;
    min-width: 300px;
    width: 30%;
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;
    padding-top: 20px;
}

.login__container > form{

    box-shadow: 0px 0px 3px rgb(103, 103, 103);
    padding: 20px;
    box-shadow: none;
    border: 1px solid rgb(161, 161, 161); 
    border-radius: 20px;
}
.login__container > h1{
    font-family: Circularxxtt, sans-serif;;
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 1.51572rem;
}

.login__container > form > h5{
    margin-bottom: 5px;
}

.login__container >form > label{
    font-family: Circularxxtt, sans-serif;;
    font-weight: 400;
    display: block;
    font-size: var(--bm-font-size-md);
    line-height: var(--bm-font-line-height-md);
    letter-spacing: var(--bm-font-letter-spacing-label);
    text-align: left;
    &::after {
        content: ' *'; 
        color: red;
    }
}
.login__container > form > label.no-asterisk::after {
    content: none;
}

.login__container > form > input{
    padding: 15px 10px;
    border-radius: 6px;
    border: none;
    font: normal 16px/normal "Times New Roman", Times, serif;
    font-family: Circularxxtt, sans-serif;;
    color: rgb(0, 0, 0);
    margin-bottom: 10px;
    background-color: rgb(237, 237, 237);
    width: 100%;
}

/* .p{
    margin-top: 15px;
    font-size: 12px;
} */

.login__signInButton {
    width: 50%; 
    background-image: linear-gradient(45deg, #5a189a, #7b2cbf);
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 15px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    color: #ffffff;
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    font-weight: bold;
    padding: 12px;
    background-image: none;
    background-color: rgba(89, 27, 176, 0.93);
    -webkit-box-shadow: 0 1px 3px 0 #000000;
    -moz-box-shadow: 0 1px 3px 0 #000000;
    box-shadow: 0 1px 1px 0 #000000;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    text-align: center;
 }
 
 .login__signInButton:hover {
    background: rgba(66, 19, 132, 0.93);
    background-image: -webkit-linear-gradient(top, #E9EAF6, #E3E4EC);
    background-image: -moz-linear-gradient(top, #E9EAF6, #E3E4EC);
    background-image: -ms-linear-gradient(top, #E9EAF6, #E3E4EC);
    background-image: -o-linear-gradient(top, #E9EAF6, #E3E4EC);
    background-image: -webkit-gradient(to bottom, #E9EAF6, #E3E4EC);
    text-decoration: none;
 } 

.login__registerButton{
    border-radius: 2px;
    width: 100%;
    height: 30px;
    border: 1px solid;
    margin-top: 10px;
    border-color: darkgray;
}

.bottom_login_form{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    font-size: 15px;
}
.bottom_login_form p{
    margin: 0 10px;
}

.bottom_login_form a{
    color: #747474;
    transition: color 0.3s ease, transform 0.3s ease; /* Smooth transition for color and transform */
}

.bottom_login_form a:hover {
    color: var(--primary-bg-color);
    transform: scale(1.1); /* Slightly increase the size */
    text-decoration: none;
  }
