:root {
  --primary-bg-color: #5a189a;
  --text-color: #ffffff;
  --font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.App-container{
  width: 100%;
}
.App {
  text-align: center;
  /* max-width: 1440px; */
  margin: 0 auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.confirmationModal{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  width: 360px;
  padding-bottom: 20px;
}

.confirmationModal h1{
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
}

.modal-ok{
  width: 100%;
  padding: 15px 0; 
  color: white; 
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  transition: background-color 0.3s ease; 
  background-color: #5a189a;
  margin-bottom: 20px;
}

.modal-ok.green{
  background-color: #006713d0;
}

.modal-ok.green:hover{
  background-color: rgba(0, 72, 13, 0.816);
}

.modal-ok.red{
  background-color: #ab0e00cc;
}

.modal-ok.red:hover{
  background-color: #820b00d7;
}

.modal-cancel{
  width: 30%;
  margin: 0 auto;
  padding: 15px 0;
  color: rgb(0, 0, 0);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 13px; 
  text-transform: uppercase;
  letter-spacing: 1.5px;
  transition: font-weight 0.3s ease;
  background-color: #ffffff;
}

.modal-cancel:hover{
  background-image: none;
  font-weight: bold;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


