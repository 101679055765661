
.customerspage{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 85vh;
}

.customerspage-header-top{
    flex: 0 0 5%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; 
    width: 100%;
}

.customerspage-header{
    flex: 0 0 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
}

.go-to-dashboard-btn{
    margin-right: auto;
    margin-left: 10px;
    margin-top: 10px;
    width: 150px;
  }
  
  .go-to-dashboard-btn button{
    width: 100%;
    font-weight: bold;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1); 
    background-color: rgb(255, 255, 255); 
  
    color: rgb(0, 0, 0);
    padding: 8px 12px;
    border: 1px solid black;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 12px;
    margin: 0 5px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  
  }

.customerspage-getinfo{
    flex: 0 0 65%;
    display: flex;
    flex-direction: column;
    align-items:center;
    margin: 0 auto;
}

.customerspage-footer{
    flex: 0 0 5%; 
    margin-top: auto;
}

.customerspage-header-businessName{
    display: flex;
    flex-direction: row;
    font-size: 35px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.customerspage-header-businessLogo{
    padding: 5px;
    margin: auto;
}
.customerspage-header-businessLogo img{
    max-width: 200px;
}



.customerspage-getinfo-input{
    flex: 0 0 45%;
    display: flex;
    flex-direction: column;
    margin:auto;
    justify-content: flex-start;
    height: 100%;
    gap:5px
}

.customerspage-getinfo-input label{
    font-size: 40px;
    font-weight: bold;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;

    /* margin-bottom: auto; */
}

.customerspage-getinfo-input-normal{
    display: none;
    padding-top: 20px;
}
.customerspage-getinfo-input-partysize{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
    gap: 10px;
    /* margin-bottom: 10%; */

}

.customerspage-getinfo-input-incdecbutton{
    margin: 0 auto;
    font-size: 80px;
    padding: 0 30px;
    background-color: white;
    border: none;
    color: black;
    cursor: pointer;
    /* font-weight: bold; */
}

.customerspage-getinfo-input-incdecbutton.dec{
    font-size: 100px;
}

.customerspage-getinfo-input-partysize-input{
    text-align: center;
    font-size: 60px;
    font-weight: bold;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    border: none;
    width: 100px;
}

.customerspage-getinfo-input-normal-input{
    text-align: center;
    font-size: 30px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    padding: 10px;
    margin: auto;
    border: 1px solid #ced4da;
    border-radius: 1px;
    width: 250px;
    /* margin-bottom: 40%; */
  }

.customerspage-getinfo-buttons{
    flex: 0 0 35%;
    align-items: center;
    display: flex;
    flex-direction:column;
    justify-content: flex-start;
    gap: 40px;
}


.customerspage-getinfo-buttons-next{
    width: 250px;
    padding: 15px 0; 
    color: #5a189a; 
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid #5a189a;
    font-size: 28px;
    font-weight: bold;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    letter-spacing: 1.5px;
    transition: background-color 0.3s ease; 
    transition: scale 0.3s ease; 

    background-color: #ffffff;
}
.customerspage-getinfo-buttons-next:hover{
    scale: 1.05;
}

.customerspage-getinfo-buttons-next.final_step{
    width: 300px;
    color: white; 
    background-color: #5a189a;
    border: none;
}

.customerspage-getinfo-buttons-back{
    display: none;
    font-size: 25px;
    cursor: pointer;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.customerspage-getinfo-buttons-back.active{
    display: block;
}

.customerspage-getinfo-steps{
    flex: 0 0 20%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 300px;
    align-items: center;
}
.customerspage-getinfo-steps.hide{
    display: none;
}
.customerspage-getinfo-step{
    background-color: #7a7a7a;
    color: white;
    border-radius: 100%;
    padding: 3px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.customerspage-getinfo-step.active{
    background-color: #5a189a;
    transition: background-color 0.3s;
}






.check-mark {
    color: green;
    font-size: 2em;
    display: flex;
    justify-content: center;
    align-items: center; 
    width: 50px; 
    height: 50px; 
    background-color: white; 
    border: 2px solid green;
    border-radius: 50%; 
    margin: 0 auto;
}

.emoji-icon {
    width: 30px;
    height: 30px;
}


@media screen and (min-width: 768px) {
    .customerspage-header{
        flex: 0 0 15%;
        gap: 1px;

    }
    .customerspage-header-businessName{
        padding-top: 5px;
        font-size: 40px;
        font-weight: bold;
    }
    .customerspage-header-businessLogo img{
        max-width: 200px;
    }
    .customerspage-getinfo{
        flex: 0 0 60%;
    }
    .customerspage-getinfo-input{
        gap: 0px;
    }

    .customerspage-getinfo-input label{
        font-size: 30px;
    }    
    

    .customerspage-getinfo-buttons{
        flex: 0 0 25%;
        gap: 10px;
    }


    .customerspage-getinfo-buttons-next{
        width: 600px;
        height: 60%;
        padding: 0px 0; 
    }

    .customerspage-getinfo-buttons-next.final_step{
        width: 700px;
    }

    .customerspage-getinfo-steps{
        width: 600px;
    }
    .customerspage-getinfo-buttons-back{
        font-size: 22px;
    }
}