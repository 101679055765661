
.customer-row {
    background-color: #f5f5f5;
    border-bottom: 10px solid #ffffff;
}

.action-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.sms-button, .delete-button {
    width: 100%;
    font-weight: bold;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
    background-color: #5a189a; 
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 12px;
    margin: 0 5px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.sms-button {
    flex: 3 0 0;
}

.delete-button {
    flex: 1 0 0;
    margin-right: 0;
    background-color: rgb(179, 0, 0); 
}

.sms-button:hover {
    background-color: #7325c1;
}

.delete-button:hover {
    background-color: #e40000; /* Darker red on hover */
}

.masked-phone {
    font-family: monospace;
    vertical-align: middle; 
  }

.service-customer-button-icon{
    color: #008332; 
    cursor: pointer;
    margin: 10px 5px;
}
.service-customer-button-icon:hover{
    color: #00a02d;
}

.remove-customer-button-icon{
    color: rgb(179, 0, 0); 
    cursor: pointer;
    margin: 10px 5px;
}

.remove-customer-button-icon:hover{
    color: #e40000;
}


@media screen and (max-width: 600px) {
    .customer-row{
        display: flex;
        flex-direction: column;
        border: 1px solid #ddd;
        margin-bottom: 10px;
        padding: 10px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }
    .sms-button{
        width: 40%;
    }

    .service-customer-button-icon{
        margin: 10px 1px;
        font-size: large;
    }
    .remove-customer-button-icon{
        margin: 10px 1px;
    }
}