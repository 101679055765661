.activate-account-btn {
    margin-left: auto; /* Push the button to the right */
    padding: 5px 10px; /* Optional: Adjust padding for better visuals */
    cursor: pointer; /* Optional: Change cursor on hover */
    background-color: rgb(0, 198, 30);
    align-items: right;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 20px;
}

.activateaccountlmodal {
    background-color: white;
    padding: 20px 20px 20px 20px; /* Increase right padding to accommodate the 'X' button */
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 450px; /* Fixed width */
    height: 280px; /* Fixed height */    margin: auto;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000; /* Ensure it's above other content */
}

.activateaccountlmodal h1 {
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
}

.activateaccountlmodal input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; /* Make sure padding doesn't affect the total width */
}

.activateaccountlmodal button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
    width: 100%;
}

.modal-action-buttons {
    display: flex;
    flex-direction: column;
    align-items: center; 
}

.modal-next, .modal-back {
    margin-bottom: 10px; /* Adds space between buttons */
}

.modal-next{
    background-color: #5a189a; 
    color: white;
}

.activateaccountmodal-ok {
    margin-bottom: 10px; /* Adds space between buttons */
    background-color: #00bd29; 
    color: rgb(0, 0, 0);
}

.modal-back {
    background-color: #ffffff; /* A warning red */
    color: rgb(0, 0, 0);
}

.activateaccountmodal-close {
    background-color: transparent;
    color: #555;
    border: none;
    font-size: 24px;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    padding: 0;
    line-height: 1;
    align-items: right;
}

.activateaccountmodal-close:hover {
    color: #000000; /* Changes color on hover for visual feedback */
}

@media screen and (max-width: 600px) {
    .shepherd-enabled.shepherd-element{
        max-width: 250px;
        z-index: 11000;
    }

    .activateaccountlmodal{
        width: 275px;
        height: 300px;
    }

}