.dashboard-container {
    display: flex;
    flex-direction: row;
    height: 100vh;
}

.n-sidebar {
    width: 15%;
    min-width: 150px;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
}

.n-sidebar-logo {
    margin-top: 20px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}

.n-sidebar-logo img{
    width: 100%;
    margin-bottom: 5px;
}

.n-sidebar-tabs{
    margin-top: 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.n-sidebar-tab{
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 10px 0;
    align-items: center;
    cursor: pointer;
}

.n-sidebar-tab.active{
    background-color: #bababac3;
    font-weight: 900;
}

.n-sidebar-tab:hover{
    font-weight: bold;
}

.n-sidebar-tab-icon{
    margin-right: 10px;
    margin-left: 15%;
}

.n-sidebar-tab-title{
    font-size: 18px;
}

.n-sidebar-logout{
    margin-top: auto;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.main-content {
    background-color: #f7f7f7;
}

.QRCode-container svg{
    margin: auto;
}

/* .customer-list {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.button:hover {
    background-color: #3c096c;
} */


/* .QR_restaurant-logo {
    max-width: 20%;
    margin-bottom: 10px;
}

.settings-click-sidebar{
    font-size: 20px;
} */


/* .main-content {
    flex-grow: 1;
    background-color: #f7f7f7;
    padding: 20px;
    overflow-x: auto;
    z-index: 0;
    flex: 12 1 auto;
} */

/* .customer-table-container{
    width: 100%;
    background-color: white;
    padding: 20px;
    border: 1px solid #ffffff;
    border-radius: 20px;

}

.customer-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 5px;
    border-radius: 10px;
    overflow: hidden;
    font-family: 'Arial', sans-serif;
    align-items: center;
  }
  

  .customer-table td {
    border: 0px solid #ddd;
    padding: 10px;
    text-align: center;
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1rem;
  }
  

  .customer-table th {
    background-color: #7b2cbf;
    color: #ffffff;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 1rem;
    text-align: center;
  } */
  
/* .customer-table th:nth-child(1) { 
    width: 10%;
}

.customer-table th:nth-child(2) { 
    width: 5%; 
}

.customer-table th:nth-child(3) { 
    width: 22%; 
}

.customer-table th:nth-child(4) {
    width: 23%;
}

.customer-table th:nth-child(5) { 
    width: 15%; 
}

.customer-table th:nth-child(6) { 
    width: 25%; 
}

  .customer-table tr:hover {
    background-color: #eee;
  } */

/* .to-customers-page {
    text-decoration: underline;
    color: rgb(0, 0, 0);
    cursor: pointer;
    font-size: 15px;
}

.settings-options {
    width: 140px;
    display: none;
    background-color: #8663a9; 
    padding: 10px 10px;
    box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.1); 
}

.settings-options.show {
    display: block;
}

.settings-options p {
    color: white;
    cursor: pointer;
    margin-bottom: 5px;
    margin-top: 5px;
    text-align: center;
} */


.subscription-plans {
    margin-top: 20px;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;

}

.plans-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; /* Adjust this as per design requirements */
}

.plan {
    flex: 0 0 30%; /* Each plan will take up roughly 30% of the container width */
    border: 1px solid #4f0262;
    background-color: #ffffff;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 5px;
    box-sizing: border-box;
    border-width: 2px;
}

.plan p {
    margin: 5px 0;
}

.plan button {
    background-image: none;
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    width: 90%;
    font-size: 10px;
}

.plan button:hover {
    background-image: none;
    background-color: #45a049;
}

/* .hamburger-icon {

    cursor: pointer;
    display: none; 
    margin-right: auto;

    font-size: 40px;
    background-color: #5a189a;
    width: 10%;
    z-index: 10000;
    position: relative;
} */

.phone-header-dashboard{
    display: none;
    align-items: center;
    justify-content: space-between;
}

.hamburger-icon {
    flex-grow: 1; 
  }

.phone-header-logo{
    flex-grow: 1;
    display: flex;
    justify-content: center;
    padding: 5px 0;
}

.phone-header-logo img {
    max-width: 80px;
    height: auto;
  }

  .placeholder {
    flex-grow: 1;
  }

.delete-all-btn {
    margin-left: auto; /* Push the button to the right */
    padding: 5px 10px; /* Optional: Adjust padding for better visuals */
    cursor: pointer; /* Optional: Change cursor on hover */
    background-color: rgb(198, 0, 0);
    align-items: right;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 12px;
}

.delete-all-btn:hover{
    background-color: red;
}


.deleteallmodal{
    position: fixed;
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    width: 360px; 
    text-align: center;
}

.deleteallmodal h1{
    font-size: 20px;
}

.QRCode{
    background-color: white;
    text-align: center;
}

.QRCode-container{
    margin:auto;
}

.QRCode-website{
    margin-top: 10px;
}

.QRCode-footer{
    margin-top: 30px;
    margin-bottom: 10px;

    width: 100%;
    font-size: 10px;
    text-align: center;
    background-color: #3c096c;
    color: white;
}

/* Responsive design */
@media screen and (max-width: 768px) {

    .dashboard-container{
        flex-direction: column;
    }

    .phone-header-dashboard{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    
    .hamburger-icon {
        display: flex;
    }

    .n-sidebar {
        display: none;
    }

    .sidebar.active {
        transition: transform 0.5s ease-in-out;
        width: 150px;
        background-image: linear-gradient( #ffffff, #8663a9, #5a189a);
    
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;



        /* display: flex;
        z-index: 1000;
        margin-left: 10%;
        height: 100%;
        transform: translateX(0); */
    }


    /* .customer-table, .customer-table thead, .customer-table tbody, .customer-table th, .customer-table td, .customer-table tr {
        display: block;
    }

    .customer-table thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .customer-table tr {
        margin: 0 0 1rem 0;
        border-bottom: 1px solid #ddd;

    }

    .customer-table td {
        border: none;
        position: relative;
        padding-left: 35%;
        text-align: center;
        border-bottom: 1px solid #ddd;
        font-size: 13px;
    }

    .customer-table td:before {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 30%;
        padding: 0 5px;
        content: attr(data-label);
        font-weight: bold;
        background-color: #7b2cbf;
        color: #ffffff; 
        text-align: center;
        font-size: 13px;
    }

    .customer-table tr td:last-child {
        border-bottom: none;
        content: none;
    } */

    /* .customer-row{
        display: flex;
        flex-direction: column;
        border: 1px solid #ddd;
        margin-bottom: 10px;
        padding: 10px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    } */

    /* .sms-button{
        font-size: 12px;
    } */
    .delete-button{
        font-size: 12px;
    }
    /* .customer-list h1{
        font-size: 20px;
    } */
    /* .action-buttons {
        justify-content: center;
        padding: 8px 0;
    } */
    /* .customer-table td[data-label="actions"]:before {
        content: none;
    }

    .customer-table td[data-label="actions"] {
        padding: 10px 0;
    } */
}
