    @import url("https://cdn.jsdelivr.net/gh/lykmapipo/themify-icons@0.1.2/css/themify-icons.css");

    .wizard-footer-right{
      background-color: red;
    }

    .wizard-progress-bar{
      background-color: red;
    }

    .wizard-card-footer {
      display: flex;
      flex-direction: column-reverse; /* Stack items vertically */
      align-items: center; /* Center items horizontally */
      justify-content: center; /* Center items vertically if there's extra space */
      gap: 10px; /* Optional: adds space between buttons */
  }

  .wizard-footer-right {
    background-color: rgb(239, 239, 239);
    font-size: large;
    width: 30%;
    min-width: 200px;
  } 

  .wizard-footer-left{
    border-color: #ffffff;
  }
  /* .wizard-footer-left {
    width: 10%;
    min-width: 200px;
  } */

  .wizard-btn {
    background-color: rgb(153, 153, 153); /* Example background color */
    border: none;
  }

  .react-form-wizard .wizard-btn{
    width: 100%;
  }

  .wizard-footer-left .wizard-btn {
    border: none; /* Removes the border */
    background-color: #ffffff; /* Example background color, adjust as needed */
    color: #000000; /* Text color */
    cursor: pointer; /* Changes cursor to pointer on hover */
  }

  .wizard-footer-left .wizard-btn:hover {
    background-color: #ffffff; /* Slightly changes background on hover */
    color: #000000;
    border: none;
  }

  .restaurant-logo {
    display: block;
    max-width: 80px; /* Adjusted for better visibility */
    margin: 0px auto; /* Increase margin for better layout */
    margin-bottom: 20px;
}



@media (max-width: 600px) {
  .restaurant-logo {
    max-width: 120px;
    margin-bottom: 20px;
  }
}