.blog-header{
    text-align: left;
    width: 60%;
    margin: 20px auto;
}

.blog-header h1{
    font-family: var(--font-family);
    font-size: 25px;
    font-weight: 700;
}

.blog-header-image{
    margin: 20px auto;
}

.blog-header-image img{
    height: auto;
    max-width: 600px;  
}

.blog-content{
    width: 60%;
    margin: 20px auto;
    text-align: left;
}

.blog-content h4{
    font-weight: 600;
}

.list-items{
    margin-left: 40px;
}

.start-using-placeinq{
    text-align: center;
}

.Blogs-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; /* Aligns items to the start of the container line */
    padding: 10px; /* Padding around the container to ensure some spacing from the edges */
  }

.blogbox {
    flex: 0 1 calc(33.333% - 20px); /* Adjust this calculation based on your actual margin/padding */
    margin: 10px; /* Margin around each box */
    max-height: 600px; 
    background-color: #fff; /* Background color for visibility */
    box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* Optional shadow for aesthetics */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px; /* Internal padding */
    box-sizing: border-box; /* Include padding and border in the width and height */
  }

  .blogbox h3{
    text-align: left;
    font-family: var(--font-family);
    font-size: 18px;
    font-weight: 700;
  }

  .blogbox p{
    text-align: left;
    font-size: 12px;
  }
  .blogbox-image{
    margin-top: 20px; 
    margin-bottom: 20px;
  }
  .blogbox-image img{
    height: auto;
    max-width: 300px;  
  }

@media (max-width: 600px) {
    .blog-header{
        width: 80%;
    }
    
    .blog-header h1{
        font-size: 20px;
    } 
    
    .blog-header-image img{
        max-width: 300px;  
    }

    .blog-content{
        width: 80%;
    }
    
    .list-items{
        margin-left: 30px;
    }

    .Blogs-container {
        justify-content: center; /* Aligns items to the start of the container line */

    }  
}  