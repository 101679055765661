.customer-table-container-publicwaitlist{
    width: 50%;
    background-color: rgb(230, 230, 230);
    padding: 20px;
    border: 1px solid #ffffff;
    border-radius: 20px;
    margin: 0 auto; /* Centers the div horizontally in the page */

}

.customer-table-publicwaitlist {
    width: 100%;
    border-collapse: collapse;
    margin-top: 5px;
    border-radius: 10px;
    overflow: hidden;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    align-items: center;
  }
  

  .customer-table-publicwaitlist td {
    border: 0px solid #ddd;
    padding: 10px;
    text-align: center;
    font-size: 1rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  }
  

  .customer-table-publicwaitlist th {
    background-color: #5a189a;
    color: #ffffff;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 1rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    text-align: center;
  }
  
/* .customer-table-publicwaitlist th:nth-child(1) {
    width: 10%;
}

.customer-table-publicwaitlist th:nth-child(2) { 
    width: 5%; 
}

.customer-table-publicwaitlist th:nth-child(3) { 
    width: 22%; 
} */



  @media (max-width: 600px) {
    .customer-table-container-publicwaitlist{
      width: 90%;
    }

  }