.Customerfooter {
    background-color: #898989; /* Light grey background */
    text-align: center;
    padding: 10px 0; /* Padding top and bottom */
    position: fixed; 
    bottom: 0; /* Stick to the bottom */
    left: 0; /* Align to the left */
    width: 100%; /* Span the full width of the viewport */
    
}
