.dashboard-waitlist{
    padding: 20px;
}

.dashboard-waitlist-headers{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 20px;
}

.dashboard-waitlist-header{
    cursor: pointer;
    color: grey;
    padding: 5px 20px;
    border: 1px solid #f7f7f7;
    border-radius: 10px 10px 0px 0px;
}

.dashboard-waitlist-header.active{
    color: black;
    background-color: rgb(210, 210, 210);

    border: 1px solid rgb(210, 210, 210);
    border-bottom: none;
}

.dashboard-waitlist-container{
    background-color: rgb(210, 210, 210);
}

.dashboard-waitlist-container-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
}

.dashboard-waitlist-container-header-columnnames{
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: rgb(100, 100, 100);
}

.dashboard-waitlist-container-header-columnname{
    margin: 0 auto;
    width: 20%;
}

.dashboard-waitlist-container-header-cta{
    color: rgb(255, 255, 255);
    background-color: green;
    cursor: pointer;
    margin-right: 20px;
    font-weight: bold;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;

    padding: 5px 25px;
    border: 1px solid green;
    border-radius: 5px;
}

.dashboard-waitlist-container-list{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
}


@media (max-width: 768px) {

    .dashboard-waitlist{
        padding: 0px;
    }

    .dashboard-waitlist-headers{
        justify-content: center;
        gap: 20px;
    }
    
    .dashboard-waitlist-header{
        width: 100%;
        padding: 15px 10px;
    }

    .dashboard-waitlist-container-header-columnnames{
        display: none;
    }

    .dashboard-waitlist-container-header{
        justify-content: flex-end;
        border-bottom: none;
    }

    .dashboard-waitlist-container-list{
        gap: 20px;
        align-items: center;
    }
}