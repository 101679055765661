@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a:hover{
  text-decoration: none;
}

@layer components {
  .placeinq-button {
    width: 100px;
    padding: 8px 14px;
    border: 1.5px solid rgb(148 163 184);
    border-radius: 12px;
    color: black;
    font-size: 1rem; /* 16px */
    line-height: 1.5rem; /* 24px */
    font-family: var(--font-family);
    box-shadow: 1px 2px 2px var(--primary-bg-color);
    font-weight: 500;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    /* <button className='w-[100px] py-2 px-3.5 border-[1.5px] text-black border-solid border-slate-400 rounded-xl shadow-signup text-base font-main font-medium 
    transition-transform hover:scale-[0.98] hover:shadow-signupInset border-placeinq'>Sign Up</button> */

  }
  .placeinq-button:hover {
    scale: 0.98;
    box-shadow: inset 1px 2px 2px var(--primary-bg-color);
    border-color: #5a189a;

  }

  .placeinq-login-input{
      background-color: #eee;
      border: none;
      margin: 8px 0;
      padding: 10px 15px;
      font-size: 13px;
      border-radius: 8px;
      width: 100%;
      outline: none;
  }
}