.settings-container{
    display: flex;
    flex-direction: column;
}

.settings-header {
    background-color: #ffffff;
    margin: 20px 10px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;

}

.settings-header p {
    cursor: pointer;
    font-size: 14px;
    border: none;
    color: rgb(0, 0, 0);
    transition: font-weight 0.3s; 
    width: 33.3%;
    margin: auto;
}

.settings-header p:hover {
    font-weight: bold;
}
.settings-header p.select {
    font-weight: bold;
    border-bottom: 1px solid rgb(142, 142, 142);
}

/* .settings-content {
    flex-grow: 1;
    background-color: #f7f7f7;
    padding: 20px;
    overflow-x: auto;
    z-index: 0;
} */


.settings-content {
    padding: 20px;
} 


.settings-content-form {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 60%;

    background-color: #fff;
    padding: 30px; 
    border-radius: 12px;
}

.settings-content-form-row {
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
}

.settings-content-form-row div{
    width: 60%;
    padding: 10px 10px;
    font-size: 14px;
    margin: auto;
    font-size: 18px;
}

.settings-content-form label {
    width: 40%;
    margin: auto 0;
    font-weight: bold;
    text-align: left;
}

.settings-content-form input{
    width: 70%;
    padding: 10px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 18px;

}

.settings-content-form button[type="submit"] {
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: #5a189a;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.settings-content-form button[type="submit"]:hover {
    background-color: #7325c1;
}

@media screen and (max-width: 768px) {

    /* .settings-header {
        flex-direction: column;
        height: 16vh;

        width: 100%;
    } */
    .settings-content-form {
        width: 90%;
    }
}