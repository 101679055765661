.signup{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color:white;
    background-position: 50% 50%;
}

.input_alert{
    color: red;
    margin-right: 100%;
    text-align: left;
}

.ErrorMessage{
    color:brown;
    font-size: 12px;
    text-align: left;
}

.login__container > form > p{
    text-align: left;
    font-size: 12px;
    margin-bottom: 0;
    margin-top: 20px;
}

/* .signup__container{
    width: 300px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    border: 1px solid rgb(10, 26, 44);
    padding: 20px;
    background-color: rgb(249, 252, 255);
    margin-bottom: 40px;
}

.signup__container > h1{
    font-weight: 500;
    margin-bottom: 2px;
    font-size:20px;
}

.signup__container > form > h5{
    margin-bottom: 5px;
}

.signup__input{

    display: inline-block;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    padding: 10px 2px;
    border: 1px solid #b7b7b7;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    font: normal 16px/normal "Times New Roman", Times, serif;
    color: rgb(0, 0, 0);
    -o-text-overflow: clip;
    text-overflow: clip;
    background: rgba(255,255,255,0.83);
    -webkit-box-shadow: 3px 3px 2px -2px rgba(43, 72, 105, 0.93) ;
    box-shadow: 3px 3px 2px -2px rgba(43, 72, 105, 0.93) ;
    text-shadow: 1px 1px 0 rgba(255,255,255,0.66) ;
    -webkit-transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
    -moz-transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
    -o-transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
    transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);

    height: 10%;
    margin-bottom: 1px;
    background-color: white;
    width: 98%;
}

.signup__checkbox {
    margin-top: 10px;
}
.signup__container > p{
    margin-top: 15px;
    font-size: 12px;
} */

/* .signup__signInButton{
    margin-top: 15px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    color: #ffffff;
    font-family: Times New Roman;
    font-size: 15px;
    font-weight: 400;
    padding: 10px;
    background-image: none;
    background-color: rgba(89, 27, 176, 0.93);
    -webkit-box-shadow: 0 1px 3px 0 #000000;
    -moz-box-shadow: 0 1px 3px 0 #000000;
    box-shadow: 0 1px 3px 0 #000000;
    border: solid #105082 1px;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    text-align: center;
}

.signup__signInButton:hover{
    border: solid #171C17 1px;
    background: rgba(66, 19, 132, 0.93);
    background-image: -webkit-linear-gradient(top, #E9EAF6, #E3E4EC);
    background-image: -moz-linear-gradient(top, #E9EAF6, #E3E4EC);
    background-image: -ms-linear-gradient(top, #E9EAF6, #E3E4EC);
    background-image: -o-linear-gradient(top, #E9EAF6, #E3E4EC);
    background-image: -webkit-gradient(to bottom, #E9EAF6, #E3E4EC);
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    text-decoration: none;
} */
